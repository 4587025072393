import React from "react"

const actions = {
    homeAction: () => {
        return <img alt="Foodgy" src={require('./img/logo192.png')} />
    },
    startersAction: () => {
        return <h1>Entrées</h1>
    }
}

export function renderController(action) {
    const controller = action + 'Action'

    let content = <h1>Page inconnue</h1>

    if (typeof actions[controller] === 'function') {
        content = actions[controller]()
    }

    return content
}
