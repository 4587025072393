import React from 'react'
import ReactDOM from 'react-dom'
import 'bootstrap/dist/css/bootstrap.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import './App/css/style.css'
import { App } from './App'
import { RecoilRoot } from 'recoil'
import * as serviceWorker from './serviceWorker'
import './App/css/fonts.css'
import { firebase } from '@firebase/app'
import { fireConf, firebaseSetup } from "./App/utils"
import '@firebase/messaging'

firebase.initializeApp(fireConf)
firebaseSetup(firebase, fireConf.vapidKey)

document.addEventListener("backbutton", () => {

})

ReactDOM.render(
    <RecoilRoot>
        <App style={{ fontFamily: 'brandon-grotesque'}} />
    </RecoilRoot>,
    document.getElementById('app')
)

serviceWorker.register()
