import React, {useEffect, useState} from 'react'
import {api, Button, Ls, $, socket, scope} from "./App/utils"
import io from "socket.io-client"
import {renderController} from "./App/Controllers";

export function App() {
    const [user, setUser] = useState(null)
    const [page, setPage] = useState('home')

    function goTo(e) {
        e.preventDefault()
        setPage(e.target.dataset.to)
    }

    useEffect(() => {
        setUser(Ls('login').get('user'))
    }, [])

    function recordUser(user) {
        setUser(user)
        Ls('login').set('user', user)
    }

    function logout(e) {
        e.preventDefault()
        Ls('login').del('user')
        setUser(null)
        setPage("home")
    }

    scope('logout', logout)

    if (!user) {
        return <Login success={recordUser}/>
    }

    if (user && user.id && !scope('bump')) {
        bump(user)
    }

    const content = renderController(page)

    return <div className="fixed-top">
        <Navbar currentPage={page} goTo={goTo} logout={logout}/>
        <div className="container mt-4">
            {content}
        </div>
    </div>
}

function Navbar({currentPage, goTo, logout}) {
    const user = scope('user')

    const navClass = function (page) {
        let className = 'nav-item'

        if (page === currentPage) {
            className += ' active item_active'
        }

        return className
    }

    const [isNavCollapsed, setIsNavCollapsed] = useState(true)

    const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed)

    const handleGo = e => {
        goTo(e)
        setIsNavCollapsed(true)
    }

    $('body').click(function (e) {
        const $target = $(e.target)
        const $parent = $target.parent()

        if ($parent.hasClass('user-profile-dropdown')) {
            $parent.addClass('show')
            $('#userProfileDropdown').addClass('show')
        } else {
            $('.show').removeClass('show')
        }
    })

    return <nav className="navbar navbar-expand-sm navbar-dark bg-primary mb-4">
        <div className="container-fluid">
            <span className="navbar-brand" onClick={ handleGo } data-to="home">
                FOODGY
            </span>

            <button className="custom-toggler navbar-toggler" type="button" data-toggle="collapse"
                    data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false"
                    aria-label="Toggle navigation" onClick={ handleNavCollapse }>
                <span className="navbar-toggler-icon"/>
            </button>

            <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarResponsive">
                <ul className="navbar-nav mr-auto">
                    <li className={ navClass('home') }>
                        <span className="nav-link" onClick={ handleGo } data-to="home">Accueil</span>
                    </li>
                    <li className={ navClass('starters') }>
                        <span className="nav-link" onClick={ handleGo } data-to="starters">Entrées</span>
                    </li>
                </ul>
                <ul className="navbar-nav flex-row ml-md-auto">
                    <li className="nav-item dropdown user-profile-dropdown">
                        <div className="nav-link dropdown-toggle"
                             data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {user.firstname}
                        </div>
                        <div id="userProfileDropdown" className="dropdown-menu position-absolute" aria-labelledby="userProfileDropdown">
                            <div className="dropdown-item" onClick={ logout }>
                                Déconnexion
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
}


function bump(user) {
    scope('user', user)

    socket(io('http://tv.cybertv.tv:8181'))

    socket().on('connect', function () {
        if (socket().io.engine.id) {
            socket().emit('accounting', {account: user})
            scope('io_id', socket().io.engine.id)

            const data = {
                id: user.id,
                socket: socket().io.engine.id,
            }

            api('socket', data, (res, error) => {
                if (error) {
                    console.error(res)
                } else {
                    console.log(res.socket, data)
                }
            })

            scope('bump', true)
        }
    })
}

function Login({success}) {
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    const handleSubmit = async function (e) {
        setError(null)
        setLoading(true)
        e.preventDefault()

        api('login', {
            email: $('#email').val(),
            password: $('#password').val(),
        }, (res, error) => {
            if (error) {
                setError(res)
                setLoading(false)
            } else {
                success(res)
            }
        })
    }

    return <div className="container mt-2">
        <div className="row">
            <div className="col-sm-3">&nbsp;</div>
            <div className="col-sm-6">
                <form className="bg-light p-4 rounded-lg login_form" onSubmit={ handleSubmit }>
                    <h2>FOODGY</h2>
                    { error && <Alert>{ error }</Alert> }
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input type="email" name="email" id="email" className="form-control" required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Mot de passe</label>
                        <input type="password" name="password" id="password" className="form-control" required />
                    </div>
                    <Button type="submit" loading={ loading }>Se connecter</Button>
                </form>
            </div>
            <div className="col-sm-3">&nbsp;</div>
        </div>
    </div>
}


function Alert({ children }) {
    return <div className="alert alert-danger">
        {children}
    </div>
}
