import ui from 'jquery'
import React, {useCallback, useState} from "react"
import {MakeConfig} from "./config/MakeConfig"

// eslint-disable-next-line no-extend-native
String.prototype.forget = function (pattern) {
    return this.split(pattern).join('');
}

const values = {}
const scopes = {}
export const $ = ui

export function Loader({ size }) {
    return <div className={"spinner-border spinner-border-" + size} role="status">
        <span className="sr-only">Chargement...</span>
    </div>
}

export function Button({ children, type = 'primary', loading = false, ...props }) {
    let className = 'btn'

    if (type === 'submit') {
        className += ' btn-primary'
    } else {
        className += ' btn-' + type
    }

    let htmlType = null

    if (type === 'submit') {
        htmlType = 'submit'
    }

    return <button className={className} type={htmlType} disabled={loading} {...props}>
        {loading ? <><Loader size="sm" /> Chargement...</> : children}
    </button>
}

export function Field({ name, children, type = 'text', error, className, ...props }) {
    return <div className={`form-group ${className}`}>
        {children && <label htmlFor={name}>{children}</label>}
        {type === 'textarea' ?
            <textarea name={name} id={name} className={`form-control${error ? ' is-invalid' : ''}`} {...props} /> :
            <input type={type} name={name} id={name} className={`form-control${error ? ' is-invalid' : ''}`} {...props} />}

        {error && <div className="invalid-feedback">{error}</div>}
    </div>
}

export function Icon({name}) {
    return <i className={"fa fa-" + name} />
}

export function Trash() {
    return <svg className="bi bi-trash-fill" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z" />
    </svg>
}

export function Upload() {
    return <svg className="bi bi-upload" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" d="M.5 8a.5.5 0 0 1 .5.5V12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V8.5a.5.5 0 0 1 1 0V12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V8.5A.5.5 0 0 1 .5 8zM5 4.854a.5.5 0 0 0 .707 0L8 2.56l2.293 2.293A.5.5 0 1 0 11 4.146L8.354 1.5a.5.5 0 0 0-.708 0L5 4.146a.5.5 0 0 0 0 .708z" />
        <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0v-8A.5.5 0 0 1 8 2z" />
    </svg>
}

export const Colors = {
    base:'#92a9b6',
    primary:'#344e5d',
    secondary:'#6b9296',
    dark:'#0e1623',
    light:'#e1e5e7',
    danger:'#5d343d',
    success:'#5d5b34',
    warning:'#bdb972',
    info:'#56819a',
}

export const Functions = {}

export function Value(v, ...a) {
    return 'function' === typeof v ? v(...a) : v
}

export function GetOr(k, d, ...a) {
    return Value(k || d, ...a)
}

export function useToggle (initialValue = true) {
    const [value, setValue] = useState(initialValue)

    const toggle = useCallback(() => {
        setValue(v => !v)
    }, [])

    return [value, toggle]
}

export function useIs (compare = true) {
    const [value, setValue] = useState(null)

    const is = useCallback(() => {
        setValue(v => v === compare)
    }, [compare])

    return [value, is]
}

export function $ui() {
    return $
}

export async function api(action, data = {}, cb) {
    MakeConfig()
    data.action = action

    let result = null
    let error = false

    $.ajax({
        type: 'POST',
        dataType: 'json',
        url: Conf('api.url'),
        async: true,
        data: data,
        error: function() {
            console.error('An error occured on ' + action + ' call.')
        },
        success: function (res) {
            if (res.error) {
                result = res.error
                error = true
            } else {
                result = res.data
            }

            cb(result, error)
        }
    })
}

export function ts() {
    return parseInt(Date.now() / 1000, 10)
}

export function Config(namespace = 'core') {
    return Data('config.' + namespace)
}

export function Env(namespace = 'core') {
    return Data('env.' + namespace)
}

export function Cache(namespace = 'core') {
    return Data('cache.' + namespace)
}

export function Store(namespace = 'core') {
    return Data('store.' + namespace)
}

export function User(k, v) {
    const store = Data('user')

    if ('undefined' === typeof v) {
        return store.get(k)
    }

    return store.set(k, v)
}

export function Conf(k, v) {
    const store = Config()

    if ('undefined' === typeof v) {
        return store.get(k)
    }

    return store.set(k, v)
}

export function Data(namespace = 'store') {
    function getKey(k) {
        return namespace + '.' + k
    }

    const get = function (k, d) {
        const dv = 'undefined' === typeof d ? null : d

        if (Data(namespace).has(k)) {
            const v = values[Data(namespace).getKey(k)]

            return 'undefined' === typeof v ? Value(dv) : Value(v)
        }

        return Value(dv)
    }

    const toggle = function (k, v) {
        const key = Data(namespace).getKey(k)

        if ('undefined' !== typeof v) {
            values[key] = v
        } else {
            v = values[key]

            if ('undefined' === typeof v) {
                v = false
            }

            values[key] = !v
        }

        return values[key]
    }

    const set = function (k, v) {
        values[Data(namespace).getKey(k)] = v

        return Data(namespace)
    }

    const incr = function (k, b = 1, d = 0) {
        const old = parseInt(Data(namespace).get(k, d), 10)
        const v = (old + b)

        Data(namespace).set(k, v)

        return v
    }

    const decr = function (k, b = 1, d = 0) {
        return Data(namespace).incr(k, b * -1, d)
    }

    const remove = function (k) {
        if (Data(namespace).has(k)) {
            delete values[Data(namespace).getKey(k)]

            return true
        }

        return false
    }

    const del = function (k) {
        return Data(namespace).remove(k)
    }

    const has = function (k) {
        const v = values[Data(namespace).getKey(k)]

        const age = values[Data(namespace).getKey(k) + '.age']

        if (typeof age !== 'undefined') {
            if (!isNaN(age)) {
                const a = parseInt(age)
                const now = parseInt(ts())

                if (now > a) {
                    Data(namespace).remove(k)

                    return false
                } else {
                    return 'undefined' !== typeof v
                }
            }
        }

        return 'undefined' !== typeof v
    }

    return {
        get,
        getKey,
        set,
        remove,
        del,
        has,
        toggle,
        incr,
        decr
    }
}

export function firebaseSetup(firebase, key) {
    if (firebase.messaging.isSupported()) {
        const messaging = firebase.messaging()
        messaging.usePublicVapidKey(key)

        messaging.onMessage((payload) => {
            console.log(payload)
        })

        messaging.onTokenRefresh(() => {
            messaging.getToken().then((refreshedToken) => {
                console.log(refreshedToken)
            }).catch((err) => {
                console.error(err)
            })
        })

        scope('messaging', messaging)
    }
}

export const fireConf = {
    vapidKey: "J03zUjfR9Bd-4W-DsgB8-Cz-TgyvNBUKUkrywV5ao44",
    apiKey: "AIzaSyBkqDz58KhaEKItiagaF8Egq206zfPCUe4",
    authDomain: "les-recettes-de-gege.firebaseapp.com",
    databaseURL: "https://les-recettes-de-gege.firebaseio.com",
    projectId: "les-recettes-de-gege",
    storageBucket: "les-recettes-de-gege.appspot.com",
    messagingSenderId: "862038001713",
    appId: "1:862038001713:web:7828670b1dccd9c2402d10"
}

export function socket(e) {
    if ('undefined' !== typeof e) {
        scope('socket', e);
    }

    return scope('socket');
}

export function scope(k, v) {
    if (typeof k === 'undefined') {
        return scopes
    }

    if (typeof v === 'undefined') {
        return scopes[k]
    }

    scopes[k] = v
}

export function Ls(namespace = 'core') {
    const getSize = function () {
        return JSON.stringify(localStorage).length
    }

    const makeKey = (k) => {
        return namespace + k
    }

    const set = function (k, v, e = null) {
        const key = 'pwa.' + makeKey(k)
        let age = parseInt(ts(), 10)
        const ageKey = makeKey(k) + '.age'

        localStorage.setItem(key, JSON.stringify(v))
        localStorage.setItem(ageKey, age)

        if (e) {
            const ttlKey = 'pwa.' + makeKey(k) + '.ttl'
            age += parseInt(e, 10)
            localStorage.setItem(ttlKey, age)
        }
    }

    const expire = function (k, v, ttl) {
        const ttlKey = 'pwa.' + makeKey(k) + '.ttl'
        const age = ts() + parseInt(ttl, 10)

        localStorage.setItem(ttlKey, age)
        Ls(namespace).set(k, v)
    }

    const get = function (k, d = null) {
        k = 'pwa.' + makeKey(k)

        const ttlKey = k + '.ttl'
        const ageKey = k + '.age'

        const val = localStorage.getItem(k)
        const valTtl = localStorage.getItem(ttlKey)

        if (valTtl) {
            if (parseInt(valTtl, 10) < parseInt(ts(), 10)) {
                localStorage.removeItem(ttlKey)
                localStorage.removeItem(ageKey)
                localStorage.removeItem(k)

                return d
            }
        }

        return val ? JSON.parse(val) : d
    }

    const getDel = function (k, d) {
        var value = Ls(namespace).get(k, d)

        Ls(namespace).del(k)

        return value
    }

    const getAge = function (k) {
        if (Ls(namespace).has(k)) {
            k = 'pwa.' + makeKey(k)
            const ageKey = k + '.age'

            const age = localStorage.getItem(ageKey)

            return parseInt(ts(), 10) - parseInt(age, 10)
        }

        return 0
    }

    const has = function (k) {
        k = 'pwa.' + makeKey(k)

        const ttlKey = k + '.ttl'
        const ageKey = k + '.age'

        const val = localStorage.getItem(k)
        const valTtl = localStorage.getItem(ttlKey)

        if (valTtl) {
            if (parseInt(valTtl, 10) < parseInt(ts(), 10)) {
                localStorage.removeItem(ttlKey)
                localStorage.removeItem(ageKey)
                localStorage.removeItem(k)

                return false
            }
        }

        return !!val
    }

    const del = function (k) {
        if (Ls(namespace).has(k)) {
            k = 'pwa.' + makeKey(k)
            const ttlKey = k + '.ttl'
            const ageKey = k + '.age'

            localStorage.removeItem(k)
            localStorage.removeItem(ttlKey)
            localStorage.removeItem(ageKey)

            return true
        }

        return false
    }

    const check = function (k, make, render, d) {
        if (Ls(namespace).has(k)) {
            render(Ls(namespace).get(k, d))
        } else {
            make()
        }
    }

    const getOr = function (k, render, v, ttl) {
        if (Ls(namespace).has(k)) {
            if (typeof render === 'function') {
                render(Ls(namespace).get(k))
            } else {
                return Ls(namespace).get(k)
            }
        } else {
            if ('function' === typeof v) {
                v = v()
            }

            if ('undefined' !== typeof v) {
                if ('undefined' === typeof ttl) {
                    Ls(namespace).set(k, v)
                } else {
                    Ls(namespace).expire(k, v, ttl)
                }

                if (typeof render === 'function') {
                    render(v)
                } else {
                    return v
                }
            }
        }
    }

    const decr = function (k, by = 1 , d = 0) {
        return Ls(namespace).incr(k, by * -1, d)
    }

    const incr = function (k, by = 1, d = 0) {
        const old = parseInt(Ls(namespace).get(k, d), 10)
        const v = (old + by)

        Ls(namespace).set(k, v)

        return v
    }

    const rename = function (k1, k2, d = null) {
        if (Ls(namespace).has(k1)) {
            const v = Ls(namespace).getAndDel(k1)
            Ls(namespace).set(k2, v)

            return true
        }

        return false
    }

    const copy = function (k1, k2, d = null) {
        if (Ls(namespace).has(k1)) {
            const v = Ls(namespace).get(k1)
            Ls(namespace).set(k2, v)
        } else {
            Ls(namespace).set(k2, d)
        }

        return true
    }

    const getAndDel = function (k, d = null) {
        if (Ls(namespace).has(k)) {
            const v = Ls(namespace).get(k)
            Ls(namespace).del(k)

            return v
        }

        return d
    }

    const getSet = function (k, v, ttl) {
        if (Ls(namespace).has(k)) {
            const old = Ls(namespace).get(k)

            if ('undefined' === typeof ttl) {
                Ls(namespace).set(k, v)
            } else {
                Ls(namespace).expire(k, v, ttl)
            }

            return old
        } else {
            if ('undefined' === typeof ttl) {
                Ls(namespace).set(k, v)
            } else {
                Ls(namespace).expire(k, v, ttl)
            }

            return v
        }
    }

    const drop = function () {
        $.each(localStorage, function(key, value) {
            if (key.match('pwa.' + namespace)) {
                localStorage.removeItem(key)
            }
        })
    }

    const dropPattern = function (pattern) {
        $.each(localStorage, function(key, value) {
            if (key.match('pwa.' + namespace)) {
                if (key.match(pattern)) {
                    localStorage.removeItem(key)
                }
            }
        })
    }

    const keys = function (pattern = '') {
        const collection = []

        $.each(localStorage, function(key, value) {
            if (key.match('pwa.' + namespace) && !key.match('.ttl') && !key.match('.age')) {
                if (key.match(pattern)) {
                    const k = key.forget('pwa.' + namespace)
                    collection.push(k)
                }
            }
        })

        return collection
    }

    const clean = function () {
        $.each(localStorage, function(key, value) {
            if (key.match('pwa.' + namespace)) {
                if (key.match('.ttl')) {
                    const k = key.forget('pwa.' + namespace).forget('.ttl')
                    const valTtl = localStorage.getItem(key)

                    if (valTtl) {
                        if (parseInt(valTtl, 10) < parseInt(ts(), 10)) {
                            Ls(namespace).del(k)
                        }
                    }
                }
            }
        })
    }

    return {
        set, 
        get, 
        getAge, 
        getAndDel, 
        getDel, 
        getOr, 
        getSet, 
        getSize, 
        has, 
        del, 
        check, 
        clean, 
        rename, 
        keys, 
        drop, 
        dropPattern, 
        copy, 
        expire, 
        incr, 
        decr
    }
}
